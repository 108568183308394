import React from 'react';

import questions from '../constants/questions';

export default function Install() {
  const question = questions[1];
  return <>
    <div className="container-mini">
      <h1>Installer l'application</h1>
      <div class="accordion">
        <div class={`accordion-item open`}>
          <div class="accordion-trigger">{question.question(false)}</div>
          <div class="accordion-content">{question.answer(false)}</div>
        </div>
      </div>
    </div>
  </>
}
